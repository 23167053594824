<template>
  <div id="sentimen-page" style="height: 100%; background-color: #161d31">
    <!-- START HEADER -->
    <div class="djp-header" align="center" style="background-color: #283046">
      <h4
        style="padding: 0px !important; margin: 0px !important; font-size: 30px"
        class="text-white"
      >
        Indosat Ooredoo Hutchison Command Center
      </h4>
      <img
        src="@/assets/logo.png"
        alt=""
        height="50"
        style="
          float: left;
          position: absolute;
          left: 100px;
          top: 0px;
          padding: 0px !important;
          margin: 0px !important;
        "
      />
      <h4
        style="
          float: right;
          position: absolute;
          right: 100px;
          top: 15px;
          padding: 0px !important;
          margin: 0px !important;
          font-size: 24px;
          cursor: pointer;
        "
        class="text-white"
        @click="showModal"
      >
        <i class="fa fa-calendar"></i>
        <span
          style="
            position: absolute;
            left: 0;
            right: 0;
            top: 11px;
            color: black;
            font-size: 10px;
          "
          id="filter-tanggal-text"
          v-html="filter_tanggal_text"
        ></span>
      </h4>
    </div>
    <!-- END HEADER -->

    <div class="container-fluid" style="height: 90% !important">
      <b-row align-v="stretch" style="height: 100%" no-gutters>
        <div
          class="col pl-0 pr-1 widget-6 card widget-loader-circle no-margin"
          style="margin-right: 5px !important; background-color: #283046"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div>
                  <h5 align="center" class="no-margin text-white">
                    Persentase Sentimen Media Konvensional
                  </h5>
                  <b-alert
                    show
                    variant="warning"
                    class="mt-2"
                    v-if="isLoading == true"
                    >Loading...</b-alert
                  >
                  <b-alert
                    show
                    variant="warning"
                    class="mt-2"
                    v-else-if="
                      formed_chartOptions == null && isLoading == false
                    "
                    >Belum ada data</b-alert
                  >

                  <vue-highcharts
                    v-else-if="formed_chartOptions"
                    type="chart"
                    :options="formed_chartOptions"
                  ></vue-highcharts>

                  <div class="row" v-if="formed_persentase">
                    <div align="center" class="col-md-4 col-sm-4">
                      <h5 class="text-white">Sentimen Positif</h5>
                      <h5 class="text-white">
                        <i class="far fa-smile icon-positif"></i>
                        {{ formed_persentase.positif }}%
                      </h5>
                    </div>
                    <div align="center" class="col-md-4 col-sm-4">
                      <h5 class="text-white">Sentimen Netral</h5>
                      <h5 class="text-white">
                        <i class="far fa-meh icon-netral"></i>
                        {{ formed_persentase.netral }}%
                      </h5>
                    </div>
                    <div align="center" class="col-md-4 col-sm-4">
                      <h5 class="text-white">Sentimen Negatif</h5>
                      <h5 class="text-white">
                        <i class="far fa-frown icon-negatif"></i>
                        {{ formed_persentase.negatif }}%
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col pl-0 pr-0 widget-6 card widget-loader-circle no-margin"
          style="margin-left: 5px !important; background-color: #283046"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="p-l-5">
                  <h5 align="center" class="no-margin text-white">
                    Persentase Sentimen Media Sosial
                  </h5>
                  <b-alert
                    show
                    variant="warning"
                    class="mt-2"
                    v-if="isLoading == true"
                    >Loading...</b-alert
                  >
                  <b-alert
                    show
                    variant="warning"
                    class="mt-2"
                    v-else-if="
                      sosmed_chartOptions == null && isLoading == false
                    "
                    >Belum ada data</b-alert
                  >

                  <vue-highcharts
                    v-if="sosmed_chartOptions"
                    type="chart"
                    :options="sosmed_chartOptions"
                  >
                  </vue-highcharts>

                  <div class="row" v-if="sosmed_persentase">
                    <div align="center" class="col-md-4 col-sm-4">
                      <h5 class="text-white">Sentimen Positif</h5>
                      <h5 class="text-white">
                        <i class="far fa-smile icon-positif"></i>
                        {{ sosmed_persentase.positif }}%
                      </h5>
                    </div>
                    <div align="center" class="col-md-4 col-sm-4">
                      <h5 class="text-white">Sentimen Netral</h5>
                      <h5 class="text-white">
                        <i class="far fa-meh icon-netral"></i>
                        {{ sosmed_persentase.netral }}%
                      </h5>
                    </div>
                    <div align="center" class="col-md-4 col-sm-4">
                      <h5 class="text-white">Sentimen Negatif</h5>
                      <h5 class="text-white">
                        <i class="far fa-frown icon-negatif"></i>
                        {{ sosmed_persentase.negatif }}%
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <b-modal
      ref="my-modal"
      title="Periode Data"
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      body-text-variant="white"
      header-text-variant="white"
      footer-border-variant="white"
      @ok="handleOK"
    >
      <div class="periode-data p-t-10">
        <div class="row">
          <div class="col">
            <b-button class="m-r-5 m-b-5" @click="setDate('1d')"
              >Hari Ini</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('7d')"
              >7 Hari</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('30d')"
              >30 Hari</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('nm')"
              >Bulan Ini</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('3m')"
              >3 Bulan</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('1y')"
              >1 Tahun</b-button
            >
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-datepicker
              id="tgl-awal"
              dark
              v-model="tgl_awal"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="Tanggal Awal"
              class="mb-2"
            ></b-form-datepicker>
          </div>
          <div class="col">
            <b-form-datepicker
              id="tgl-akhir"
              v-model="tgl_akhir"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="Tanggal Akhir"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueHighcharts from "vue2-highcharts";
import { BAlert, BModal, BFormDatepicker, BRow, BIcon } from "bootstrap-vue";
import { formatDateParam, getDateDiff } from "@/plugins/helper.js";
export default {
  name: "sentimenPage",
  components: {
    VueHighcharts,
    "b-alert": BAlert,
    BModal,
    BFormDatepicker,
    BRow,
    BIcon,
  },
  data() {
    return {
      sosmed_chartOptions: null,
      formed_chartOptions: null,
      sosmed_persentase: null,
      formed_persentase: null,
      filter_tanggal_text: "<i class='fa fa-timer' />",
      tgl_awal: "",
      tgl_akhir: "",
      isLoading: true,
      api: process.env.VUE_APP_API_URL,
      intervalFunc: () => {},
    };
  },
  methods: {
    getData: function (show_loading = true) {
      let q_param = {
        from: formatDateParam(this.tgl_awal) + " 00:00:00",
        to: formatDateParam(this.tgl_akhir) + " 23:59:59",
      };
      if (show_loading) {
        this.sosmed_chartOptions = null;
        this.formed_chartOptions = null;
        this.sosmed_persentase = null;
        this.formed_persentase = null;
      }
      this.$http
        .get(this.api + "/cc-pie-charts/" + process.env.VUE_APP_ID_PROJECT, {
          params: q_param,
        })
        .then((response) => {
          let v_sentiment = {
            positif: 0,
            netral: 0,
            negatif: 0,
          };
          let vk_sentiment = {
            positif: 0,
            netral: 0,
            negatif: 0,
          };
          if (response.status == 200) {
            v_sentiment["positif"] = response.data.bar_sosmed.positif;
            v_sentiment["netral"] = response.data.bar_sosmed.netral;
            v_sentiment["negatif"] = response.data.bar_sosmed.negatif;
            vk_sentiment["positif"] = response.data.bar_formed.positif;
            vk_sentiment["netral"] = response.data.bar_formed.netral;
            vk_sentiment["negatif"] = response.data.bar_formed.negatif;
            if (response.data.bar_sosmed.total > 0) {
              this.sosmed_persentase = {
                positif: (
                  (response.data.bar_sosmed.positif /
                    response.data.bar_sosmed.total) *
                  100
                )
                  .toFixed(2)
                  .replace(".", ","),
                netral: (
                  (response.data.bar_sosmed.netral /
                    response.data.bar_sosmed.total) *
                  100
                )
                  .toFixed(2)
                  .replace(".", ","),
                negatif: (
                  (response.data.bar_sosmed.negatif /
                    response.data.bar_sosmed.total) *
                  100
                )
                  .toFixed(2)
                  .replace(".", ","),
              };
            } else {
              this.sosmed_persentase = {
                positif: 0,
                netral: 0,
                negatif: 0,
              };
            }

            if (response.data.bar_formed.total > 0) {
              this.formed_persentase = {
                positif: (
                  (response.data.bar_formed.positif /
                    response.data.bar_formed.total) *
                  100
                )
                  .toFixed(2)
                  .replace(".", ","),
                netral: (
                  (response.data.bar_formed.netral /
                    response.data.bar_formed.total) *
                  100
                )
                  .toFixed(2)
                  .replace(".", ","),
                negatif: (
                  (response.data.bar_formed.negatif /
                    response.data.bar_formed.total) *
                  100
                )
                  .toFixed(2)
                  .replace(".", ","),
              };
            } else {
              this.formed_persentase = {
                positif: 0,
                netral: 0,
                negatif: 0,
              };
            }

            this.formed_chartOptions = {
              chart: {
                backgroundColor: "#283046",
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
                height: "70%",
              },
              credits: {
                enabled: false,
              },
              title: {
                text: "",
              },
              legend: {
                itemStyle: { color: "#ffffff" },
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: "pointer",
                  dataLabels: {
                    enabled: false,
                  },
                  showInLegend: true,
                },
              },
              series: [
                {
                  name: "Sentiment",
                  data: [
                    {
                      name: "Positif",
                      y: vk_sentiment["positif"],
                      color: "#28a745",
                    },
                    {
                      name: "Netral",
                      y: vk_sentiment["netral"],
                      color: "#17a2b8",
                    },
                    {
                      name: "Negatif",
                      y: vk_sentiment["negatif"],
                      color: "#dc3545",
                    },
                  ],
                },
              ],
            };
          } else {
            this.formed_persentase = {
              netral: 0,
              senang: 0,
              sedih: 0,
              takut: 0,
              marah: 0,
            };
          }

          this.sosmed_chartOptions = {
            chart: {
              backgroundColor: "#283046",
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: "pie",
              height: "70%",
            },
            credits: {
              enabled: false,
            },
            title: {
              text: "",
            },
            legend: {
              itemStyle: { color: "#ffffff" },
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: false,
                },
                showInLegend: true,
              },
            },
            series: [
              {
                name: "Sentiment",
                data: [
                  {
                    name: "Positif",
                    y: v_sentiment["positif"],
                    color: "#28a745",
                  },
                  {
                    name: "Netral",
                    y: v_sentiment["netral"],
                    color: "#17a2b8",
                  },
                  {
                    name: "Negatif",
                    y: v_sentiment["negatif"],
                    color: "#dc3545",
                  },
                ],
              },
            ],
          };
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showModal: function () {
      this.$refs["my-modal"].show();
    },
    setDefaultDate: function () {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      this.tgl_akhir = new Date(today);
      this.tgl_awal = new Date(today);
      this.tgl_awal.setDate(today.getDate() - 7);
      this.filter_tanggal_text = "7D";
    },
    handleOK: function () {
      this.getData();
      let a = formatDateParam(this.tgl_awal);
      let b = formatDateParam(this.tgl_akhir);
      let day_count = parseInt(getDateDiff(a, b)) + 1;
      if (day_count > 99) {
        this.filter_tanggal_text = Math.ceil(day_count / 30) + "M";
      } else {
        let date_diff_month = day_count / 30;
        if (date_diff_month % 1 == 0) {
          this.filter_tanggal_text = date_diff_month + "M";
        } else {
          this.filter_tanggal_text = day_count + "D";
        }
      }
    },
    setDate: function (shortcode = "1d") {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      switch (shortcode) {
        case "1d":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          break;
        case "7d":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setDate(today.getDate() - 6);
          break;
        case "30d":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setDate(today.getDate() - 29);
          break;
        case "nm":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setMonth(today.getMonth());
          this.tgl_awal.setDate(1);
          break;
        case "3m":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setMonth(today.getMonth() - 3);
          this.tgl_awal.setDate(today.getDate() + 1);
          break;
        case "1y":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setFullYear(today.getFullYear() - 1);
          this.tgl_awal.setDate(today.getDate() + 1);
          break;
        default:
          // statements_def
          break;
      }
    },
  },
  created: function () {
    this.setDefaultDate();
    this.getData();
  },
};
</script>