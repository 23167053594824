<template>
  <div id="sentimen-page" style="height: 100%; background-color: #161d31">
    <!-- START HEADER -->
    <div class="djp-header" align="center" style="background-color: #283046">
      <h4
        style="padding: 0px !important; margin: 0px !important; font-size: 30px"
        class="text-white"
      >
        Indosat Ooredoo Hutchison Command Center
      </h4>
      <img
        src="@/assets/logo.png"
        alt=""
        height="50"
        style="
          float: left;
          position: absolute;
          left: 100px;
          top: 0px;
          padding: 0px !important;
          margin: 0px !important;
        "
      />
      <h4
        style="
          float: right;
          position: absolute;
          right: 100px;
          top: 15px;
          padding: 0px !important;
          margin: 0px !important;
          font-size: 24px;
          cursor: pointer;
        "
        class="text-white"
        @click="showModal"
      >
        <i class="fa fa-calendar"></i>
        <span
          style="
            position: absolute;
            left: 0;
            right: 0;
            top: 11px;
            color: black;
            font-size: 10px;
          "
          id="filter-tanggal-text"
          v-html="filter_tanggal_text"
        ></span>
      </h4>
    </div>
    <!-- END HEADER -->

    <div class="container-fluid" style="height: 90% !important">
      <b-row align-v="stretch" style="height: 100%" no-gutters>
        <div
          class="col pl-0 pr-0 widget-6 card widget-loader-circle no-margin"
          id="posting-pers-div"
          style="
            margin-left: 5px !important;
            height: 100%;
            overflow-y: auto;
            background-color: #283046;
          "
        >
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 mb-5">
                <h5
                  align="center"
                  class="no-margin pb-3 sticky text-white"
                  style="background-color: #283046"
                >
                  POSTING PERS
                </h5>
                <div class="post-wrapper">
                  <b-alert show variant="warning" v-if="isLoading == true"
                    >Loading...</b-alert
                  >
                  <b-alert
                    show
                    variant="warning"
                    v-else-if="
                      (posting_pers == null || posting_pers.length == 0) &&
                      isLoading == false
                    "
                    >Belum ada data
                  </b-alert>
                  <posting-list-component
                    :simple_meta="true"
                    v-else-if="posting_pers && posting_pers.length > 0"
                    v-for="(post, index) in posting_pers"
                    :key="index"
                    :post="post"
                  />
                </div>
                <div id="end-of-posting-pers"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col pl-0 pr-0 widget-6 card widget-loader-circle no-margin"
          id="posting-private-div"
          style="
            margin-left: 5px !important;
            height: 100%;
            overflow-y: auto;
            background-color: #283046;
          "
        >
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 mb-5">
                <h5
                  align="center"
                  class="no-margin pb-3 sticky text-white"
                  style="background-color: #283046"
                >
                  POSTING PRIVATE
                </h5>
                <div class="post-wrapper">
                  <b-alert show variant="warning" v-if="isLoading == true"
                    >Loading...</b-alert
                  >
                  <b-alert
                    show
                    variant="warning"
                    v-else-if="
                      (posting_private == null ||
                        posting_private.length == 0) &&
                      isLoading == false
                    "
                    >Belum ada data
                  </b-alert>
                  <posting-list-component
                    :simple_meta="true"
                    v-else-if="posting_private"
                    v-for="(post, index) in posting_private"
                    :key="index"
                    :post="post"
                  />
                </div>
                <div id="end-of-posting-private"></div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col pl-0 pr-0 widget-6 card widget-loader-circle no-margin"
          id="posting-lembaga-div"
          style="
            margin-left: 5px !important;
            height: 100%;
            overflow-y: auto;
            background-color: #283046;
          "
        >
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12 mb-5">
                <h5
                  align="center"
                  class="no-margin pb-3 sticky text-white"
                  style="background-color: #283046"
                >
                  POSTING LEMBAGA
                </h5>
                <div class="post-wrapper">
                  <b-alert show variant="warning" v-if="isLoading == true"
                    >Loading...</b-alert
                  >
                  <b-alert
                    show
                    variant="warning"
                    v-else-if="
                      (posting_lembaga == null ||
                        posting_lembaga.length == 0) &&
                      isLoading == false
                    "
                    >Belum ada data
                  </b-alert>

                  <posting-list-component
                    :simple_meta="true"
                    v-else-if="posting_lembaga"
                    v-for="(post, index) in posting_lembaga"
                    :key="index"
                    :post="post"
                  />
                </div>
                <div id="end-of-posting-lembaga"></div>
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <b-modal
      ref="my-modal"
      title="Periode Data"
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
      body-text-variant="white"
      header-text-variant="white"
      footer-border-variant="white"
      @ok="handleOK"
    >
      <div class="periode-data p-t-10">
        <div class="row">
          <div class="col">
            <b-button class="m-r-5 m-b-5" @click="setDate('1d')"
              >Hari Ini</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('7d')"
              >7 Hari</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('30d')"
              >30 Hari</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('nm')"
              >Bulan Ini</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('3m')"
              >3 Bulan</b-button
            >
            <b-button class="m-r-5 m-b-5" @click="setDate('1y')"
              >1 Tahun</b-button
            >
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-form-datepicker
              id="tgl-awal"
              dark
              v-model="tgl_awal"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="Tanggal Awal"
              class="mb-2"
            ></b-form-datepicker>
          </div>
          <div class="col">
            <b-form-datepicker
              id="tgl-akhir"
              v-model="tgl_akhir"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="Tanggal Akhir"
              class="mb-2"
            ></b-form-datepicker>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import profic from "@/assets/logo.png";
import postingList from "@/components/postingList.vue";
import { BAlert, BModal, BFormDatepicker, BRow } from "bootstrap-vue";
import { formatDateParam, getDateDiff } from "@/plugins/helper.js";
import { scroller } from "vue-scrollto/src/scrollTo";

export default {
  name: "postingPage",
  components: {
    "posting-list-component": postingList,
    "b-alert": BAlert,
    BRow,
  },
  data() {
    return {
      profic: profic,
      posting_pers: null,
      posting_lembaga: null,
      posting_robot: null,
      posting_private: null,
      filter_tanggal_text: "<i class='fa fa-timer' />",
      tgl_awal: "",
      tgl_akhir: "",
      isLoading: true,
      api: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    getData: function (show_loading = true) {
      let q_param = {
        from: formatDateParam(this.tgl_awal) + " 00:00:00",
        to: formatDateParam(this.tgl_akhir) + " 23:59:59",
      };
      if (show_loading) {
        this.posting_pers = null;
        this.posting_lembaga = null;
        this.posting_private = null;
      }
      this.$http
        .get(
          this.api + "/cc-category-posts/" + process.env.VUE_APP_ID_PROJECT,
          { params: q_param }
        )
        .then((response) => {
          if (response.status == 200) {
            this.posting_pers = response.data.pers_posts;
            this.posting_lembaga = response.data.lembaga_posts;
            this.posting_private = response.data.private_posts;
            // this.posting_robot = response.data.robot_posts = []
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          let scroll_duration;

          scroll_duration = this.posting_pers.length * 4000;
          this.scrollDiv(
            "#posting-pers-div",
            "#end-of-posting-pers",
            scroll_duration
          );
          // this.scrollDiv('#posting-robot-div', '#end-of-posting-robot', 20000)
          scroll_duration = this.posting_lembaga.length * 4000;
          this.scrollDiv(
            "#posting-lembaga-div",
            "#end-of-posting-lembaga",
            scroll_duration
          );
          scroll_duration = this.posting_private.length * 4000;
          this.scrollDiv(
            "#posting-private-div",
            "#end-of-posting-private",
            scroll_duration
          );
        });
    },
    scrollDiv: function (container, scrolltodiv, duration) {
      let self = this;
      var options = {
        container: container,
        easing: "ease-in",
        lazy: false,
        offset: -60,
        force: true,
        cancelable: true,
        onStart: function (element) {
          // scrolling started
        },
        onDone: function (element) {
          document.getElementById(container.replace("#", "")).scrollTop = 0;
          self.scrollDiv(container, scrolltodiv, duration);
        },
        onCancel: function () {
          // scrolling has been interrupted
        },
        x: false,
        y: true,
      };

      let the_var = scroller();

      the_var(scrolltodiv, duration, options);
    },
    showModal: function () {
      this.$refs["my-modal"].show();
    },
    setDefaultDate: function () {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      this.tgl_akhir = new Date(today);
      this.tgl_awal = new Date(today);
      this.tgl_awal.setDate(today.getDate() - 7);
      this.filter_tanggal_text = "7D";
    },
    handleOK: function () {
      this.getData();
      let a = formatDateParam(this.tgl_awal);
      let b = formatDateParam(this.tgl_akhir);
      let day_count = parseInt(getDateDiff(a, b)) + 1;
      if (day_count > 99) {
        this.filter_tanggal_text = Math.ceil(day_count / 30) + "M";
      } else {
        let date_diff_month = day_count / 30;
        if (date_diff_month % 1 == 0) {
          this.filter_tanggal_text = date_diff_month + "M";
        } else {
          this.filter_tanggal_text = day_count + "D";
        }
      }
    },
    setDate: function (shortcode = "1d") {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      switch (shortcode) {
        case "1d":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          break;
        case "7d":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setDate(today.getDate() - 6);
          break;
        case "30d":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setDate(today.getDate() - 29);
          break;
        case "nm":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setMonth(today.getMonth());
          this.tgl_awal.setDate(1);
          break;
        case "3m":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setMonth(today.getMonth() - 3);
          this.tgl_awal.setDate(today.getDate() + 1);
          break;
        case "1y":
          this.tgl_akhir = new Date(today);
          this.tgl_awal = new Date(today);
          this.tgl_awal.setFullYear(today.getFullYear() - 1);
          this.tgl_awal.setDate(today.getDate() + 1);
          break;
        default:
          // statements_def
          break;
      }
    },
  },
  created: function () {
    this.setDefaultDate();
    this.getData();
  },
};
</script>